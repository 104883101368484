<template>
  <img class="not-found-img" :src="notFoundUrl" :alt="altUrl" />
</template>
<script>
export default {
  setup: () => ({
    public_url: process.env.BASE_URL
  }),
  props: ['altUrl'],
  computed: {
    notFoundUrl() {
      return this.public_url + 'not_found.jpg';
    }
  }
};
</script>
<style scoped>
.not-found-img {
  width: 8em;
  height: auto;
  border-radius: 5px;
}

/* @media (min-width: 1100px) {
  .not-found-img {
    width: 30%;
  }
} */
</style>
