<template>
  <TraceRecordInference
    v-if="$route.params.traceId"
    :fetchPresignedUrl="fetchPresignedUrl"
  />
  <a-row
    v-else
    id="trace-page-container"
    :gutter="[16, 16]"
    class="py-4 px-2 p-md-4"
  >
    <a-col span="24">
      <Filters
        ref="filtersComponent"
        :show-cycle-input="true"
        :show-duration-input="true"
        :show-steps-filter="true"
        :is-no-data-available="false"
        :is-analytics="false"
        @change="handleGetData"
        @changePage="
          (page) => {
            pagination.currentPage = page;
          }
        "
        @updateCards="
          (data) => updateCards(headerCards, headerCardsSecondRow, data)
        "
      />
    </a-col>
    <a-col span="24" class="text-right">
      <a-button
        v-if="!isNoDataAvailable"
        id="trace-page-analytics-btn"
        :disabled="isFetchingCycles"
        type="primary"
        class="ml-auto"
        @click="$refs.filtersComponent.redirectToPage('Analytics')"
      >
        Analytics
        <template #icon>
          <i class="bi bi-box-arrow-up-right mr-2" />
        </template>
      </a-button>
    </a-col>

    <a-col span="24">
      <a-divider orientation="left" class="mt-2"> Dashboard </a-divider>
      <a-typography
        v-if="isDataNotFound && !isFetchingCycles"
        class="text-center mb-3"
        :level="4"
      >
        No Data Available
      </a-typography>
    </a-col>
    <a-col
      v-for="(card, cardIndex) in cards"
      :key="card.key"
      :xs="card.colXS"
      :sm="card.colSM"
      :md="card.colMD"
      :lg="card.colLG"
      :xl="card.colXL"
    >
      <CardItem :card="card" :card-index="cardIndex" />
    </a-col>

    <!-- Top Five Percentile -->
    <a-col
      v-if="topFivePercentile.length"
      id="trace-page-dashboard-video-box"
      class="mb-3"
      span="24"
    >
      <video-listing
        :total-videos="totalPercentileCount"
        :list="topFivePercentile"
        :title="'Interesting Cycles'"
        :cycle="cycle_type"
        :get-video-s3-details="getVideoS3Details"
        :fetch-presigned-url="fetchPresignedUrl"
        :is-interesting-cycles="true"
        :is-loading="isFetchingTopFivePercentile"
        @updateVideo="updateVideo"
        @updateVideoUrl="updateVideoUrlInVideosAndPercentileVideos"
      />
      <a-pagination
        v-if="totalPercentileCount > 0"
        id="trace-page-dashboard-videos-pagination"
        v-model:current="percentilePagination.currentPage"
        v-model:pageSize="percentilePagination.pageSize"
        class="mx-auto mt-2 text-center"
        :total="totalPercentileCount"
        show-size-changer
        :show-total="
          (total, range) => `${range[0]}-${range[1]} of ${total} cycles`
        "
        :page-size-options="percentilePagination.options"
      />
    </a-col>

    <!-- Video Listing -->
    <a-col id="trace-page-dashboard-video-box" span="24">
      <video-listing
        :total-videos="videoCount"
        :list="videoList"
        :cycle="cycle_type"
        :is-interesting-cycles="false"
        :get-video-s3-details="getVideoS3Details"
        :fetch-presigned-url="fetchPresignedUrl"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        :is-loading="isFetchingTrace"
        @bookMarkVideo="bookMarkVideo"
        @updateVideoListCount="updateVideoListCount"
        @handleCycleCount="handleCycleCount"
        @archivedCycleCount="archivedCycleCount"
        @handlePageChange="handleCurrentPageChange"
        @updateVideo="updateVideo"
        @updateVideoUrl="updateVideoUrlInVideosAndPercentileVideos"
      />
      <a-pagination
        v-if="videoCount > 0 && !isFetchingCycles"
        id="trace-page-dashboard-videos-pagination"
        v-model:current="pagination.currentPage"
        v-model:pageSize="pagination.pageSize"
        class="mx-auto mt-2 text-center"
        :total="videoCount"
        show-size-changer
        :show-total="
          (total, range) => `${range[0]}-${range[1]} of ${total} cycles`
        "
        :page-size-options="pagination.options"
        @change="onPageChange"
      />
    </a-col>
  </a-row>
</template>

<script>
import { cards } from 'src/config/charts-config.js';
import httpClient from 'src/service/httpClient';
import TelemetryService from 'src/services/telemetry';
import { mapActions, mapGetters } from 'vuex';
import traceMixin from '../../../../mixins/analyticsTrace';
import Filters from '../../../shared/Components/AdvanceFilters';
import CardItem from '../../../shared/Components/CardItem.vue';
import getTime from '../../../shared/Helpers/getTime';
import TraceRecordInference from './TraceRecordInference.vue';
import VideoListing from './VideoListing.vue';

import { deepClone } from 'src/utils/task';

export default {
  components: {
    TraceRecordInference,
    VideoListing,
    CardItem,
    Filters,
  },
  mixins: [traceMixin],
  inject: ['toast'],

  data() {
    return {
      cards: deepClone(cards),
      cardsData: [],
      isDataNotFound: false,
      cycle_type: 'all_cycles',
      videoList: [],
      topFivePercentile: [],
      videoCount: 0,
      hasFetchCalled: false,
      pagination: {
        pageSize: 6,
        currentPage: 1,
        options: [6, 10, 20, 50],
      },
      isFetchingTopFivePercentile: false,
      totalPercentileCount: 0,
      percentilePagination: {
        pageSize: 6,
        currentPage: 1,
        options: [6, 10, 20, 50],
      },
      selectedFilters: null,
    };
  },

  computed: {
    ...mapGetters([
      'organization',
      'isFetchingCycles',
      'isFetchingTrace',
      'selectedCycleType',
      'isMissedStepsSelected',
    ]),
    isNoDataAvailable() {
      return Object.keys(this.cardsData).length === 0;
    },
  },

  watch: {
    'percentilePagination.pageSize'() {
      this.fetchTopFivePercentile();
    },
    'percentilePagination.currentPage'() {
      this.fetchTopFivePercentile();
    },
  },

  created() {
    this.populatePaginationParams();
  },

  methods: {
    ...mapActions(['setIsFetchingCycles']),

    handleCycleCount(is_good_cycle) {
      this.cards = this.cards.map((data) => {
        if (data.key === 'number_of_cycles') {
          if (is_good_cycle) {
            if (this.isMissedStepsSelected) {
              data.value = data.value - 1;
            } else {
              data.goodCycles = data.goodCycles + 1;
            }
            data.badCycles = data.badCycles - 1;
          } else {
            data.goodCycles = data.goodCycles - 1;
            data.badCycles = data.badCycles + 1;
          }
        }
        return data;
      });
    },

    archivedCycleCount(is_good_cycle) {
      this.cards = this.cards.map((data) => {
        if (data.key === 'number_of_cycles') {
          if (is_good_cycle) {
            data.value = data.value - 1;
            data.goodCycles = data.goodCycles - 1;
          } else {
            data.value = data.value - 1;
            data.badCycles = data.badCycles - 1;
          }
        }
        return data;
      });
    },

    populatePaginationParams() {
      const query = this.$route.query;
      if (!query.page) return;
      this.pagination.currentPage = Number(query.page) || 1;
      this.pagination.pageSize = Number(query.size) || 6;
    },

    async fetchAndUpdateThumbnails(videosWithoutThumbnail) {
      this.hasFetchCalled = true;
      let thumbnailRes = [];
      for await (const video of videosWithoutThumbnail) {
        const { bucket, filePath: path } = this.getVideoS3Details(video);
        const url = await this.fetchPresignedUrl(bucket, path);
        const thumbnail_res = await this.getThumbnailPath(url, bucket, path);
        thumbnailRes.push(thumbnail_res?.thumbnail_path);
        if (thumbnail_res.thumbnail_path) {
          await this.updateTaskRecord(thumbnail_res.thumbnail_path, video);
        }
      }
      if (!thumbnailRes.some((t) => !t)) this.hasFetchCalled = false;
    },

    getVideoS3Details(video, isPredictionFile = false) {
      return this.$refs.filtersComponent.getVideoS3Details(
        video,
        isPredictionFile
      );
    },

    async fetchPresignedUrl(bucket_name, object_path) {
      const payload = {
        bucket_name,
        object_path,
      };
      return new Promise(async (resolve) => {
        const res = await httpClient.post(
          'generic/generate_new_url/',
          payload,
          false,
          false,
          false
        );
        resolve(res.presigned_url || res);
      });
    },

    async getThumbnailPath(file_url, bucket_name, file_path) {
      const payload = {
        file_url,
        bucket_name,
        file_path,
      };
      return await httpClient.post(
        'generic/create_thumbnail/',
        payload,
        false,
        false,
        false
      );
    },

    updateTaskRecord(updatedData, videoToUpdate) {
      const updateVideoParams = {
        videoName: videoToUpdate.fileName,
        updatedObj: updatedData,
      };
      return new Promise(async (resolve) => {
        this.updateVideo(updateVideoParams);
        await httpClient.patch(
          'organization/task_record/',
          videoToUpdate.id + '/',
          updatedData,
          false,
          false,
          false
        );
        resolve();
      });
    },

    updateVideo(params) {
      this.updateVideoDetails(params, 'videoList');
      this.updateVideoDetails(params, 'topFivePercentile');
    },

    updateVideoDetails(params, stateKey) {
      const { videoName, updatedObj } = params;
      const temp = [...this[stateKey]];
      const index = temp.findIndex((v) => v.fileName === videoName);
      if (temp[index]) {
        temp[index] = {
          ...temp[index],
          ...updatedObj,
        };
      }
      this[stateKey] = temp;
    },

    handleCurrentPageChange(page, imageChangeCb) {
      if (!page) return;
      this.pagination.currentPage = page;
      this.hasFetchCalled = false;
      this.$refs.filtersComponent.getData(true, imageChangeCb);
    },

    onPageChange() {
      this.hasFetchCalled = false;
      this.$refs.filtersComponent.getData(true);
    },

    getAverageCycleTime(sumOfCyclesTime, cyclesCount) {
      const averageCycleTime = sumOfCyclesTime / cyclesCount;
      return getTime(averageCycleTime * 1000);
    },

    async handleGetData(
      filters,
      isTraceOnly = false,
      imageChangeCb = () => {}
    ) {
      if (!filters.cyclesFromParams && !isTraceOnly) {
        this.getTaskData(filters);
      }
      if (!filters.cycle_type) {
        filters.cycle_type = this.selectedCycleType;
      }
      const { currentPage, pageSize } = this.pagination;

      this.getVideoList({
        ...filters,
        page: currentPage,
        page_size: pageSize,
      });
      if (imageChangeCb) imageChangeCb();
    },

    async getTaskData(filters) {
      if (Array.isArray(this.cyclesFromParams)) return;
      delete filters.cycle_type;
      this.selectedFilters = filters;
      this.setIsFetchingCycles(true);
      this.percentilePagination.currentPage = 1;
      this.fetchTopFivePercentile();
      const [error, data] = await TelemetryService.fetchCyclesDetailsV2(false, {
        ...filters,
        response_data_type: 'cycle_stats',
      });
      this.setIsFetchingCycles(false);
      if ((data?.body && data?.body == 'No result found') || error) {
        this.clearCards();
        this.cardsData = {};
        this.isDataNotFound = true;
        return;
      }

      this.isDataNotFound = false;
      this.cardsData = data.cycle_stats;
      this.updateCards();
    },

    async fetchTopFivePercentile() {
      const { currentPage, pageSize } = this.percentilePagination;
      const filters = {
        ...this.selectedFilters,
        page: currentPage,
        page_size: pageSize,
      };
      this.isFetchingTopFivePercentile = true;
      const [error, data] = await TelemetryService.fetchTopFivePercentile(
        false,
        filters
      );
      this.isFetchingTopFivePercentile = false;
      if (error) {
        this.toast.error('Error Occurred while fetching top five percentile!');
        console.log({ error });
        return;
      }
      const topFivePercentileData = data.results.map((v) =>
        this.appendMissedSteps(v)
      );
      this.topFivePercentile = topFivePercentileData.map((v) => ({
        ...v,
        isThumbnailExist: true,
      }));
      this.totalPercentileCount = data.count;
    },

    updateCards() {
      this.cards = this.cards.map((card) => {
        card['value'] = this.cardsData[card.key];
        if (Object.hasOwn(card, 'goodCycles'))
          card['goodCycles'] = this.cardsData['cycles_without_errors'];
        if (Object.hasOwn(card, 'badCycles'))
          card['badCycles'] = this.cardsData['cycles_with_errors'];
        if (Object.hasOwn(card, 'percentile'))
          card['percentile'] = this.cardsData['top_five_percentile']?.length
            ? this.cardsData['top_five_percentile'].length
            : 0;
        return card;
      });
    },

    clearCards() {
      this.cards = this.cards.map((card) => {
        card['value'] = 0;
        if (Object.hasOwn(card, 'goodCycles')) card['goodCycles'] = 0;
        if (Object.hasOwn(card, 'badCycles')) card['badCycles'] = 0;
        if (Object.hasOwn(card, 'percentile')) card['percentile'] = 0;
        return card;
      });
    },

    updateVideoUrlInVideosAndPercentileVideos(videoName, url) {
      this.updateVideoUrl(videoName, url, 'videoList');
      this.updateVideoUrl(videoName, url, 'topFivePercentile');
    },

    bookMarkVideo({ videoName, updatedObj }) {
      this.videoList = this.videoList.map((data) => {
        if (data.fileName == videoName) {
          return { ...data, starred: updatedObj.starred };
        }
        return { ...data };
      });
    },

    updateVideoListCount(videoId, CommentCount) {
      this.videoList = this.videoList.map((data) => {
        if (data.id == videoId) {
          return { ...data, no_of_comments: CommentCount };
        }
        return { ...data };
      });
    },

    updateVideoUrl(videoName, url, stateKey) {
      const temp = [...this[stateKey]];
      const index = temp.findIndex((v) => v.cycle_identifier === videoName);
      temp[index].video_url = temp[index].fileURL = url;
      this[stateKey] = temp;
    },
  },
};
</script>

<style>
ul {
  list-style-type: none;
  padding: 0px;
}

li.li {
  display: inline;
  margin: 0px 10px;
}

#trace-video-pagination
  ul
  > li:last-child
  .ant-pagination-options-size-changer.ant-select {
  width: 86px !important;
}
</style>
